@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

html,
body {
  /* minimal breakpoint 475px */
  @apply text-Color__Text;
  width: auto !important;
  scrollbar-gutter: stable;
}

a:hover {
  @apply underline;
}

@media only screen and (max-width: 474px) {
  html {
    /* workaround to comprise 475px layout on smaller screens
      without hacky initial-scale, transform, as everythings in REM */
    font-size: 14px;
  }
}

ul,
ol,
li {
  @apply list-inside;
}

.product-desc p {
  @apply mb-2;
}

.content-block ul {
  @apply list-disc;
}

.content-block ol {
  @apply list-decimal;
}

.content-block li {
  @apply my-2;
}

footer ul li {
  list-style: none;
}

.grecaptcha-badge {
  display: none;
}

@media only screen and (max-width: 500px) {
  #chat-button {
    bottom: 100px !important;
  }
}

apple-wallet-button {
  --apple-wallet-button-border-radius: 32px;
  --apple-wallet-button-height: 49;
  --apple-wallet-button-width: 282;
  --apple-wallet-button-padding: 6px 10px;
  --apple-wallet-button-box-sizing: content-box;
}

.skip-link {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background: #f2e9b5;
  border: solid 2px #111827;
  border-top: none;
  padding: 8px;
  z-index: 10000;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: top 0.3s;
}

.skip-link:focus-within {
  top: 0px;
  visibility: visible;
}

.skip-link a {
  color: #000;
  text-decoration: underline;
  outline: none;
}

.skip-link a:focus {
  outline: 2px solid #0056b3;
}
